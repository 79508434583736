@media only screen and (max-width: 991px) {
  #navbarSupportedContent {
    position: absolute;
    right: 0;
    top: 2.5rem;
    background-color: #333;
    padding: 20px;
    z-index: 9999;
  }
  .nav-link {
    color: white !important;
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
}
